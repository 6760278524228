import "@/styles/style.scss"
import 'aos/dist/aos.css'
import "./src/mockEnv"
import React, { useEffect } from "react"
import { createRoot, hydrateRoot } from 'react-dom/client'
import type { GatsbyBrowser } from "gatsby"
import ReduxProvider from "./src/redux/provider"
import ThemeProvider from "./src/theme"
import Layout from "./src/layout"
import AccountLayout from "./src/components/pages/Account/__layout"
import ArmoryLayout from "./src/components/pages/Armory/__layout"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <ReduxProvider>
      <ThemeProvider>
        {element}
      </ThemeProvider>
    </ReduxProvider>
  )
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const path = props.location.pathname
  if (path.startsWith('/account')) {
    return <Layout {...props} children={<AccountLayout {...props} children={element} />} />
  }
  if (path.startsWith('/armory')) {
    return <Layout {...props} children={<ArmoryLayout {...props} children={element} />} />
  }
  return <Layout {...props} children={element} />
}

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    createRoot(container).render(element)
  }
}