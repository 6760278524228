import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { amountWithCommas } from "@/utils"
import { useLocation } from "@reach/router"
import * as style from "./style.module.scss"
import Emoji from "@/components/common/Emoji"

const Menu = () => {
  const remoteModel = useAppSelector((state) => state.account.remoteModel)
  const location = useLocation()
  const isGamesSection = location.pathname.startsWith("/games")

  return (
    <div className={style.menu}>
      <div
        className={classnames(style.menuGames, {
          [style.menuGamesVisible]: isGamesSection,
        })}
      >
        <div className={style.menuGamesInner}>
          <Link
            to="/games/clicker/"
            className={classnames(style.menuGamesLink, {
              [style.menuGamesLinkActive]: location.pathname === "/games/clicker/",
            })}
          >
            Clicker
          </Link>
          <Link
            to="/games/treasure/"
            className={classnames(style.menuGamesLink, {
              [style.menuGamesLinkActive]: location.pathname === "/games/treasure/",
            })}
          >
            Treasure
          </Link>
          <Link
            to="/games/pvp/"
            className={classnames(style.menuGamesLink, {
              [style.menuGamesLinkActive]: location.pathname === "/games/pvp/",
            })}
          >
            PvP
          </Link>
        </div>
      </div>
      <div className={style.menuInner}>
        <Link to="/power" className={style.link} activeClassName={style.linkActive}>
          <div className={style.linkInner}>
            <Emoji type="zap" size={32} />
            <span className={style.linkText}>Power</span>
          </div>
        </Link>
        <Link
          to="/armory/store"
          className={classnames(style.link, {
            [style.linkActive]: location.pathname.startsWith("/armory"),
          })}
        >
          <div className={style.linkInner}>
            <Emoji type="money-bag" size={32} />
            <span className={style.linkText}>Armory</span>
          </div>
        </Link>
        <Link
          to="/games/clicker"
          className={classnames(style.link, style.linkGames, {
            [style.linkActive]: location.pathname.startsWith("/games"),
          })}
        >
          <div className={style.linkInner}>
            <Emoji type="game" size={32} />
            <span className={style.linkText}>Games</span>
          </div>
        </Link>
        <Link to="/referrals" className={style.link} activeClassName={style.linkActive}>
          <div className={style.linkInner}>
            <div className={style.count}>{amountWithCommas(remoteModel?.refsCount || 0)}</div>
            <Emoji type="pig-face" size={32} />
            <span className={style.linkText}>Referrals</span>
          </div>
        </Link>
        <Link
          to="/account/leaders"
          className={classnames(style.link, {
            [style.linkActive]: location.pathname.startsWith("/account"),
          })}
        >
          <div className={style.linkInner}>
            <Emoji type="winner" size={32} />
            <span className={style.linkText}>Account</span>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Menu
