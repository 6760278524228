import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { Link, navigate } from "gatsby"
import { Tag, Tabs } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"

const AccountLayout = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation()
  return (
    <div>
      <div>
        <Tabs
          activeKey={location?.pathname}
          className="xray-tabs-menu xray-tabs-links"
          destroyInactiveTabPane={true}
          items={[
            { key: "/account/leaders/", label: <Link to="/account/leaders/">Leaders</Link> },
            { key: "/account/wallet/", label: <Link to="/account/wallet/">Wallet</Link> },
            { key: "/account/stats/", label: <Link to="/account/stats/">Stats</Link> },
            { key: "/account/settings/", label: <Link to="/account/settings/">Settings</Link> },
          ]}
        />
      </div>
      {children}
    </div>
  )
}

export default AccountLayout
