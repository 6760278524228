import React, { useEffect, useState } from "react"
import AOS from "aos"
import classnames from "classnames"
import { Helmet } from "react-helmet"
import Menu from "@/components/common/Menu"
import Lock from "@/components/common/Lock"
import ModalPower from "@/components/modals/Power"
import ModalUnlock from "@/components/modals/Unlock"
import ModalUserInfo from "@/components/modals/UserInfo"
import * as style from "./style.module.scss"

const MainLayout = ({ children, location }: { children: React.ReactNode; location: any }) => {
  useEffect(() => {
    AOS.init({
      duration: 700,
      once: true,
      mirror: false,
    })
  }, [])

  return (
    <>
      <Helmet title="Pigz.io The Game">
        <meta
          name="viewport"
          content="width=device-width,viewport-fit=cover,initial-scale=1,shrink-to-fit=no,maximum-scale=1,user-scalable=0"
        />
        <link rel="stylesheet" type="text/css" href="/font/satoshi.css" />
        <link rel="stylesheet" type="text/css" href="/font/xray-icons.css" />
      </Helmet>
      <ModalPower />
      <ModalUnlock />
      <ModalUserInfo />
      <Lock />
      <div className={classnames(style.layout)}>{children}</div>
      <Menu />
    </>
  )
}

export default MainLayout
