import React from "react"
import * as style from "./style.module.scss"

const Emoji = ({ type, size = 21 }: { type: string; size?: 21 | 24 | 27 | 32 }) => {
  return (
    <span className={style.emoji}>
      <img src={`/images/emoji/${type}.png`} alt={type} className={style[`size${size}`]} />
    </span>
  )
}

export default Emoji
