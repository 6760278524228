import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"

const Loading = () => {
  return (
    <div className="text-center py-4">
      <Spin indicator={<LoadingOutlined spin />} size="large" />
    </div>
  )
}

export default Loading
