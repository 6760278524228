import { type ConfettiOptions } from "@stevent-team/react-party/dist/lib/options"

export const COMMIT_INTERVAL = 10_000
export const XCOIN_DECIMALS = 6
export const XDIAMOND_DECIMALS = 0
export const XRAY_DECIMALS = 6

export const AVAILABLE_PLATFORMS = ["ios", "android", "android_x", "macos", "tdesktop", "unigram"]
export const LOCAL_STORAGE_PREFIX_GLOBAL = "pigz.tg."

export const CONFETTI_OPTIONS: Partial<ConfettiOptions> = {
  count: 5,
  spawnGap: -100,
  speed: 1.2,
  diameter: [10, 20],
  shapeWeights: {
    star: 1,
    moon: 1,
    square: 2,
  },
}
