import React, { useState, useEffect } from "react"
import { QRCode } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import * as style from "./style.module.scss"

const Lock = () => {
  const locked = useAppSelector((state) => state.app.locked)
  const loaded = useAppSelector((state) => state.app.loaded)

  return (
    <>
      {locked && (
        <div className={style.lock}>
          <h3 className="mb-1">Mobile Gaming Rocks!</h3>
          <p className="text-muted mb-1">Enjoy the game from your mobile device:</p>
          <p className="mb-3">@pigz_io_bot</p>
          <div className={style.qr}>
            <QRCode color="#ffffff" type="svg" size={200} bordered={false} value="https://t.me/pigz_io_bot" />
          </div>
        </div>
      )}
    </>
  )
}

export default Lock
