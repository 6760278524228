import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { Link, navigate } from "gatsby"
import { Tag, Tabs } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"

const ArmoryLayout = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation()
  return (
    <div>
      <div>
        <Tabs
          activeKey={location?.pathname}
          className="xray-tabs-menu xray-tabs-links"
          destroyInactiveTabPane={true}
          items={[
            { key: "/armory/store/", label: <Link to="/armory/store/">Store</Link> },
            { key: "/armory/mint/", label: <Link to="/armory/mint/">Mint</Link> },
            { key: "/armory/pigz/", label: <Link to="/armory/pigz/">Pigz</Link> },
            { key: "/armory/items/", label: <Link to="/armory/items/">Items</Link> },
          ]}
        />
      </div>
      {children}
    </div>
  )
}

export default ArmoryLayout
