import { AccountModel } from "@/services/api/types"

/**
 * Account Types
 */

export interface IRemoteModel extends AccountModel {}
export interface ILocalModel {
  xcoin: number
  levelClicks: number
  clicksPigBase: number
  clicksPigStarred: number
}

export interface ISettings {
  selectedPigId: number
  isStarredPigSelected: boolean
  payPerClickXcoin: number
  payPerClickMultiplier: number
  locked: boolean
  auto: boolean
  localModel: ILocalModel
  remoteModel: IRemoteModel | undefined
}

/**
 * Account Actions
 */

export enum Enum {
  CLEAR = "account/CLEAR",
  INIT_ACCOUNT = "account/INIT_ACCOUNT",

  ACCOUNT_INFO_UPDATE_SAGA = "account/ACCOUNT_INFO_UPDATE_SAGA",
  SELECTED_PIG_ID_SET = "account/SELECTED_PIG_ID_SET",
  IS_STARRED_PIG_SELECTED_SET = "account/IS_STARRED_PIG_SELECTED_SET",
  PAY_PER_CLICK_SET = "account/PAY_PER_CLICK_SET",
  LOCKED_SET = "account/LOCKED_SET",
  AUTO_SET = "account/AUTO_SET",
  REMOTE_MODEL_SET = "account/REMOTE_MODEL_SET",
  LOCAL_MODEL_SET = "account/LOCAL_MODEL_SET",
  CLICK_UPDATE_SAGA = "account/CLICK_UPDATE_SAGA",
  COMMIT_CLICKS_SAGA = "account/COMMIT_CLICKS_SAGA",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type AInitAccount = {
  type: typeof Enum.INIT_ACCOUNT
}

export type AAccountInfoUpdateSaga = {
  type: typeof Enum.ACCOUNT_INFO_UPDATE_SAGA
}

export type ASelectPigIdSet = {
  type: typeof Enum.SELECTED_PIG_ID_SET
  selectedPigId: number
}

export type AIsStarredPigSelectedSet = {
  type: typeof Enum.IS_STARRED_PIG_SELECTED_SET
  isStarredPigSelected: boolean
}

export type APayPerClickSet = {
  type: typeof Enum.PAY_PER_CLICK_SET
  payPerClickXcoin: number
  payPerClickMultiplier: number
}

export type ALockedSet = {
  type: typeof Enum.LOCKED_SET
  locked: boolean
}

export type AAutoSet = {
  type: typeof Enum.AUTO_SET
  auto: boolean
}

export type ARemoteModelSet = {
  type: typeof Enum.REMOTE_MODEL_SET
  remoteModel: IRemoteModel
}

export type ALocalModelSet = {
  type: typeof Enum.LOCAL_MODEL_SET
  localModel: ILocalModel
}

export type AClickUpdateSaga = {
  type: typeof Enum.CLICK_UPDATE_SAGA
}

export type ACommitClicksSaga = {
  type: typeof Enum.COMMIT_CLICKS_SAGA
}

export type Actions =
  | AClear
  | AInitAccount
  | AAccountInfoUpdateSaga
  | ASelectPigIdSet
  | AIsStarredPigSelectedSet
  | APayPerClickSet
  | ALockedSet
  | AAutoSet
  | ARemoteModelSet
  | ALocalModelSet
  | AClickUpdateSaga
  | ACommitClicksSaga
