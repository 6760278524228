import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { Modal, Button, Tabs, Col, Row } from "antd"
import { backButton } from "@telegram-apps/sdk-react"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AppActions, AppTypes } from "@/redux/app"
import { amountFormat, amountFormatSplit, amountWithCommas, roundTo } from "@/utils"

const Data = () => {
  const dispatch = useAppDispatch()
  const modalUserInfo = useAppSelector((state) => state.app.modalUserInfo)
  const data = modalUserInfo.rankingModel

  return (
    <div>
      <Row gutter={10}>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">Clicks</div>
          <h6 className="mb-3">{amountWithCommas(data?.clicksTotal || 0)}</h6>
        </Col>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">Rank</div>
          <h6 className="mb-3">{amountWithCommas(data?.rank || 0)}</h6>
        </Col>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">Level</div>
          <h6 className="mb-3">{amountWithCommas(data?.level || 0)}</h6>
        </Col>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">Account Power</div>
          <h6 className="mb-3">x{data?.accountPower || "—"}</h6>
        </Col>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">Referrals</div>
          <h6 className="mb-3">{amountWithCommas(data?.refsCount || 0)}</h6>
        </Col>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">Pigz Unlocked</div>
          <h6 className="mb-3">{data?.unlockedPigz.length || 0} / 24</h6>
        </Col>
        <Col span={24}>
          <div className="xray-line xray-line-dashed mb-3" />
        </Col>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">XCOIN Balance</div>
          <h6 className="mb-3">
            {amountFormat((data?.xcoin || 0) / 1000000, 6).a}
            <sup>.{amountFormat((data?.xcoin || 0) / 1000000, 6).b}</sup>
          </h6>
        </Col>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">XDIAMOND Balance</div>
          <h6 className="mb-3">{amountWithCommas(data?.xdiamond || 0)}</h6>
        </Col>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">XCOIN Earned</div>
          <h6 className="mb-3">
            {amountFormat((data?.xcoinTotal || 0) / 1000000, 6).a}
            <sup>.{amountFormat((data?.xcoinTotal || 0) / 1000000, 6).b}</sup>
          </h6>
        </Col>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">XDIAMOND Earned</div>
          <h6 className="mb-3">{amountWithCommas(data?.xdiamondTotal || 0)}</h6>
        </Col>
        <Col span={24}>
          <div className="xray-line xray-line-dashed mb-3" />
        </Col>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">Account ID</div>
          <h6 className="mb-3">{data?.id || "—"}</h6>
        </Col>
        <Col span={12}>
          <div className="text-muted font-size-14 mb-1">Registration Date</div>
          <h6 className="mb-3">{data?.createdAt ? new Date(data?.createdAt).toLocaleDateString() : "—"}</h6>
        </Col>
      </Row>
    </div>
  )
}

export default Data
