exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-leaders-tsx": () => import("./../../../src/pages/account/leaders.tsx" /* webpackChunkName: "component---src-pages-account-leaders-tsx" */),
  "component---src-pages-account-settings-tsx": () => import("./../../../src/pages/account/settings.tsx" /* webpackChunkName: "component---src-pages-account-settings-tsx" */),
  "component---src-pages-account-stats-tsx": () => import("./../../../src/pages/account/stats.tsx" /* webpackChunkName: "component---src-pages-account-stats-tsx" */),
  "component---src-pages-account-wallet-tsx": () => import("./../../../src/pages/account/wallet.tsx" /* webpackChunkName: "component---src-pages-account-wallet-tsx" */),
  "component---src-pages-armory-items-tsx": () => import("./../../../src/pages/armory/items.tsx" /* webpackChunkName: "component---src-pages-armory-items-tsx" */),
  "component---src-pages-armory-mint-tsx": () => import("./../../../src/pages/armory/mint.tsx" /* webpackChunkName: "component---src-pages-armory-mint-tsx" */),
  "component---src-pages-armory-pigz-tsx": () => import("./../../../src/pages/armory/pigz.tsx" /* webpackChunkName: "component---src-pages-armory-pigz-tsx" */),
  "component---src-pages-armory-store-tsx": () => import("./../../../src/pages/armory/store.tsx" /* webpackChunkName: "component---src-pages-armory-store-tsx" */),
  "component---src-pages-games-clicker-tsx": () => import("./../../../src/pages/games/clicker.tsx" /* webpackChunkName: "component---src-pages-games-clicker-tsx" */),
  "component---src-pages-games-pvp-tsx": () => import("./../../../src/pages/games/pvp.tsx" /* webpackChunkName: "component---src-pages-games-pvp-tsx" */),
  "component---src-pages-games-treasure-tsx": () => import("./../../../src/pages/games/treasure.tsx" /* webpackChunkName: "component---src-pages-games-treasure-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-power-tsx": () => import("./../../../src/pages/power.tsx" /* webpackChunkName: "component---src-pages-power-tsx" */),
  "component---src-pages-referrals-tsx": () => import("./../../../src/pages/referrals.tsx" /* webpackChunkName: "component---src-pages-referrals-tsx" */)
}

