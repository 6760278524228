import * as Types from "./types"

export const getAccount = async (initDataRaw: string): Promise<Types.AccountModel & Types.Error> => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/account/model`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      auth: {
        type: "telegram",
        initDataRaw: initDataRaw,
      },
    }),
  })
  return await result.json()
}

export const commitClicks = async (
  initDataRaw: string,
  clicksPigBase: number,
  clicksPigStarred: number
): Promise<Types.AccountModel & Types.Error> => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/account/commit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      auth: {
        type: "telegram",
        initDataRaw: initDataRaw,
      },
      clicksPigBase,
      clicksPigStarred,
    }),
  })
  return await result.json()
}

export const unlockPig = async (initDataRaw: string, pigId: number): Promise<Types.UnlockPig & Types.Error> => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/account/unlock_pig`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      auth: {
        type: "telegram",
        initDataRaw: initDataRaw,
      },
      pigId,
    }),
  })
  return await result.json()
}

export const getAccountRankingByIds = async (ids: number[]): Promise<Types.AccountRankingModel[] & Types.Error> => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/stats/account_ranking`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      accountIds: ids,
    }),
  })
  return await result.json()
}

export const getAccountRanking = async (
  offset?: number,
  limit?: number
): Promise<Types.AccountRankingModel[] & Types.Error> => {
  const result = await fetch(
    `${process.env.GATSBY_REALM_URL}/stats/account_ranking?offset=${offset || 0}&limit=${limit || 100}`
  )
  return await result.json()
}

export const getGameStats = async (): Promise<Types.GameStats & Types.Error> => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/stats/game_stats`)
  return await result.json()
}

export const getAccountClicksHistory = async (
  id?: number,
  type?: "day" | "hour" | "minute"
): Promise<Types.AccountClicksHistory[] & Types.Error> => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/stats/account_clicks_history?id=${id}&type=${type}`)
  return await result.json()
}

export const getTreasureGame = async (initDataRaw: string): Promise<Types.TreasureGame & Types.Error> => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/account/get_treasure_game`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      auth: {
        type: "telegram",
        initDataRaw: initDataRaw,
      },
    }),
  })
  return await result.json()
}

export const openTreasureGame = async (
  initDataRaw: string,
  treasureId: "treasure_1" | "treasure_2" | "treasure_3"
): Promise<Types.TreasureGame & Types.Error> => {
  const result = await fetch(`${process.env.GATSBY_REALM_URL}/account/open_treasure_game`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      auth: {
        type: "telegram",
        initDataRaw: initDataRaw,
      },
      treasureId,
    }),
  })
  return await result.json()
}
