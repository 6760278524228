import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { Modal, Button, Tabs, Col, Row } from "antd"
import { backButton } from "@telegram-apps/sdk-react"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AppActions, AppTypes } from "@/redux/app"
import UserInfo from "./userInfo"
import ClicksHistory from "./clicksHistory"

const ModalUserInfo = () => {
  const dispatch = useAppDispatch()
  const modalUserInfo = useAppSelector((state) => state.app.modalUserInfo)

  const closeModal = () => {
    dispatch(AppActions.MODAL_USER_INFO_SET(false, modalUserInfo.tab, modalUserInfo.id, modalUserInfo.rankingModel))
  }

  useEffect(() => {
    if (modalUserInfo.opened) {
      backButton.mount()
      backButton.show()
      return backButton.onClick(closeModal)
    }
    backButton.hide()
  }, [modalUserInfo.opened])

  return (
    <Modal
      title={null}
      open={modalUserInfo.opened}
      onCancel={() => closeModal()}
      footer={null}
      width={420}
      closeIcon={<span className="xi xi-close" />}
      destroyOnClose
    >
      <div>
        <div>
          <Tabs
            defaultActiveKey={modalUserInfo.tab}
            // destroyInactiveTabPane
            items={[
              {
                key: "user_info",
                label: "User Info",
                children: <UserInfo />,
              },
              {
                key: "clicks_history",
                label: "Clicks History",
                children: <ClicksHistory />,
              },
            ]}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ModalUserInfo
