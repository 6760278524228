import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { Modal, Button, Tabs } from "antd"
import { backButton } from "@telegram-apps/sdk-react"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AppActions, AppTypes } from "@/redux/app"
import { amountFormat, amountFormatSplit, amountWithCommas, roundTo } from "@/utils"
import * as style from "./style.module.scss"

const ModalPower = () => {
  const dispatch = useAppDispatch()
  const modalPower = useAppSelector((state) => state.app.modalPower)
  const selectedPigId = useAppSelector((state) => state.account.selectedPigId)
  const remoteModel = useAppSelector((state) => state.account.remoteModel)
  const payPerClickMultiplier = useAppSelector((state) => state.account.payPerClickMultiplier)
  const isStarredPig = selectedPigId === remoteModel?.starredPigId

  const closeModal = () => {
    dispatch(AppActions.MODAL_POWER_SET(false, modalPower.tab))
  }

  useEffect(() => {
    if (modalPower.opened) {
      backButton.mount()
      backButton.show()
      return backButton.onClick(closeModal)
    }
    backButton.hide()
  }, [modalPower.opened])

  return (
    <Modal
      title={null}
      open={modalPower.opened}
      onCancel={() => closeModal()}
      footer={null}
      width={420}
      closeIcon={<span className="xi xi-close" />}
      destroyOnClose
    >
      {modalPower.tab === "about" && (
        <>
          <Tabs
            className="xray-tabs-nomore"
            items={[
              {
                key: "account_power",
                label: "Account Power",
                children: (
                  <div className="text-muted">
                    <div className="text-center text-center mb-4">
                      <img src="/stickers/flying_coin.png" alt="Power" className="w-80p" />
                    </div>
                    <p>
                      Account Power is your key to skyrocketing your progress! Earn these powerful boosts through
                      achievements, upgrades, or special challenges.
                    </p>
                    <p>
                      Activate them to multiply your clicks and watch your score soar. Keep an eye out for opportunities
                      to activate your Account Power and maximize your rewards!
                    </p>
                    <p>
                      The final multiplier is the multiplication of all multipliers. Your current multiplier is x
                      {payPerClickMultiplier}
                    </p>
                  </div>
                ),
              },
            ]}
          />
        </>
      )}
      {modalPower.tab === "account" && (
        <>
          <Tabs
            className="xray-tabs-nomore"
            items={[
              {
                key: "selected_pig",
                label: "Selected Pig",
                children: (
                  <div className="text-muted">
                    <p>
                      Every 6 hours a new Starred Pig is chosen, which has an increased random multiplier from x1 to x5.
                      So save up your XDIAMONDs to unlock the right piglet in time to tap on it!
                    </p>
                    <div
                      className={classnames("xray-multi", {
                        "xray-multi-active": isStarredPig,
                      })}
                    >
                      <div className="xray-multi-title">
                        {remoteModel?.pigz.find((pig) => pig.id === remoteModel.starredPigId)?.name || "—"}
                        <div className="font-size-12">Starred Pig</div>
                      </div>
                      <div className="xray-multi-count">x2.24</div>
                    </div>
                    <div
                      className={classnames("xray-multi", {
                        "xray-multi-active": !isStarredPig,
                      })}
                    >
                      <div className="xray-multi-title">
                        Rest Pigz
                        <div className="font-size-12">Base Pig</div>
                      </div>
                      <div className="xray-multi-count">x1</div>
                    </div>
                  </div>
                ),
              },
              {
                key: "level",
                label: "Level",
                children: (
                  <div>
                    <div className="text-muted">
                      <p>Click to upgrade your account level to increase your multiplier! Take care of your finger!</p>
                    </div>
                    {Array.from({ length: remoteModel?.boosts?.accountLevel?.gradient.max || 0 }).map((item, index) => {
                      const levelClicks = amountWithCommas(
                        index * (remoteModel?.boosts?.accountLevel?.gradient.clicks || 0) + 5000
                      )
                      const totalClicks = amountWithCommas(
                        Array.from({ length: index }).reduce(
                          (acc: number, _, i) =>
                            acc + (i + 1) * (remoteModel?.boosts?.accountLevel?.gradient.clicks || 0),
                          0
                        )
                      )
                      return (
                        <div
                          key={index}
                          className={classnames("xray-multi", {
                            "xray-multi-active": remoteModel?.boosts?.accountLevel.level === index,
                          })}
                        >
                          <div className="xray-multi-title">
                            Level {index}
                            <div className="font-size-12">
                              <span className="xray-multi-title-muted">
                                {index + 1 !== remoteModel?.boosts?.accountLevel?.gradient.max ? levelClicks : "—"}{" "}
                                clicks to next level
                                <br />
                                {totalClicks} total clicks
                              </span>
                            </div>
                          </div>
                          <div className="xray-multi-count">
                            x{roundTo(index * (remoteModel?.boosts?.accountLevel?.gradient.growth || 0) + 1)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ),
              },
              {
                key: "referrals",
                label: "Referrals",
                children: (
                  <div>
                    <div className="text-muted">
                      <p>
                        Invite your friends into the game to make your multiplier increase. One of the most effective
                        ways to increase Boost Power quickly.
                      </p>
                    </div>
                    {Array.from({ length: remoteModel?.boosts?.referrals?.gradient.max || 0 }).map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classnames("xray-multi", {
                            "xray-multi-active": remoteModel?.boosts?.referrals.level === index,
                          })}
                        >
                          <div className="xray-multi-title">
                            {index} referral(s)
                            <br />
                            <span className="xray-multi-title-muted"></span>
                          </div>
                          <div className="xray-multi-count">
                            x{roundTo(index * (remoteModel?.boosts?.referrals?.gradient.growth || 0) + 1)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ),
              },
            ]}
          />
        </>
      )}
      {modalPower.tab === "ingame_balance" && (
        <>
          <div className="mb-3">
            <strong>In-Game Balance</strong>
          </div>
          <div className="text-muted">
            <p>
              In-game balance is the balance from which you can make in-game purchases, buy various boosters, and mint
              NFTs. You can both topup the balance and withdraw: our tokens are real tokens on the Cardano blockchain!
              Ada has no impact on multiples, it is needed to pay commissions for transactions related to the Cardano
              blockchain.
            </p>
          </div>
          <Tabs
            className="xray-tabs-nomore"
            items={[
              {
                key: "xcoin",
                label: "XCOIN",
                children: (
                  <div>
                    {remoteModel?.boosts?.xcoin?.gradient.levels.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classnames("xray-multi", {
                            "xray-multi-active": remoteModel?.boosts?.xcoin.level === index,
                          })}
                        >
                          <div className="xray-multi-title">
                            &gt;{amountFormat((item || 0) / 1000000, 6).a}
                            <sup className="xray-multi-title-muted">.{amountFormat((item || 0) / 1000000, 6).b}</sup>
                          </div>
                          <div className="xray-multi-count">
                            x{roundTo(index * (remoteModel?.boosts?.xcoin?.gradient.growth || 0) + 1)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ),
              },
              {
                key: "xdiamond",
                label: "XDIAMOND",
                children: (
                  <div>
                    {remoteModel?.boosts?.xdiamond?.gradient.levels.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classnames("xray-multi", {
                            "xray-multi-active": remoteModel?.boosts?.xdiamond.level === index,
                          })}
                        >
                          <div className="xray-multi-title">&gt;{item}</div>
                          <div className="xray-multi-count">
                            x{roundTo(index * (remoteModel?.boosts?.xdiamond?.gradient.growth || 0) + 1)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ),
              },
              {
                key: "xray",
                label: "XRAY",
                children: (
                  <div>
                    {remoteModel?.boosts?.xray?.gradient.levels.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classnames("xray-multi", {
                            "xray-multi-active": remoteModel?.boosts?.xray.level === index,
                          })}
                        >
                          <div className="xray-multi-title">
                            &gt;{amountFormat((item || 0) / 1000000, 6).a}
                            <sup className="xray-multi-title-muted">.{amountFormat((item || 0) / 1000000, 6).b}</sup>
                          </div>
                          <div className="xray-multi-count">
                            x{roundTo(index * (remoteModel?.boosts?.xray?.gradient.growth || 0) + 1)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ),
              },
            ]}
          />
        </>
      )}
      {modalPower.tab === "wallet_balance" && (
        <>
          <div className="mb-3">
            <strong>Cardano Wallet Balance</strong>
          </div>
          <div className="text-muted">
            <p>
              Tokens that are on your wallet (not in the game), also affect your multipliers, but with a smaller
              coefficient, so keeping the balance in the game is more profitable. Ada has no impact on multiples.
            </p>
          </div>
          <Tabs
            className="xray-tabs-nomore"
            items={[
              {
                key: "xcoin",
                label: "XCOIN",
                children: (
                  <div>
                    {remoteModel?.boosts?.cardanoXcoin?.gradient.levels.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classnames("xray-multi", {
                            "xray-multi-active": remoteModel?.boosts?.cardanoXcoin.level === index,
                          })}
                        >
                          <div className="xray-multi-title">
                            &gt;{amountFormat((item || 0) / 1000000, 6).a}
                            <sup className="xray-multi-title-muted">.{amountFormat((item || 0) / 1000000, 6).b}</sup>
                          </div>
                          <div className="xray-multi-count">
                            x
                            {roundTo(
                              (index * (remoteModel?.boosts?.cardanoXcoin?.gradient.growth || 0)) /
                                remoteModel?.boosts?.cardanoXcoin.gradient.cardanoWalletDivider +
                                1
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ),
              },
              {
                key: "xdiamond",
                label: "XDIAMOND",
                children: (
                  <div>
                    {remoteModel?.boosts?.cardanoXdiamond?.gradient.levels.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classnames("xray-multi", {
                            "xray-multi-active": remoteModel?.boosts?.cardanoXdiamond.level === index,
                          })}
                        >
                          <div className="xray-multi-title">&gt;{item}</div>
                          <div className="xray-multi-count">
                            x
                            {roundTo(
                              (index * (remoteModel?.boosts?.cardanoXdiamond?.gradient.growth || 0)) /
                                remoteModel?.boosts?.cardanoXdiamond.gradient.cardanoWalletDivider +
                                1
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ),
              },
              {
                key: "xray",
                label: "XRAY",
                children: (
                  <div>
                    {remoteModel?.boosts?.cardanoXray?.gradient.levels.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classnames("xray-multi", {
                            "xray-multi-active": remoteModel?.boosts?.cardanoXray.level === index,
                          })}
                        >
                          <div className="xray-multi-title">
                            &gt;{amountFormat((item || 0) / 1000000, 6).a}
                            <sup className="xray-multi-title-muted">.{amountFormat((item || 0) / 1000000, 6).b}</sup>
                          </div>
                          <div className="xray-multi-count">
                            x
                            {roundTo(
                              (index * (remoteModel?.boosts?.cardanoXray?.gradient.growth || 0)) /
                                remoteModel?.boosts?.cardanoXray.gradient.cardanoWalletDivider +
                                1
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ),
              },
            ]}
          />
        </>
      )}
    </Modal>
  )
}

export default ModalPower
